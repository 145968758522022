<script setup>
import shuffle from 'lodash-es/shuffle.js';

const colors = ['#98AFF5', '#F7931A', '#DA0E3E', '#22C55E', '#CE9FFC', '#2775CA'];
const colorsShuffled = shuffle(colors);
</script>
<template>
    <svg fill="none" height="18" viewBox="0 0 114 18" width="114" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M23.6561 16.9223V18H18.1372L16.406 13.9988H7.75005L6.01886 18H0.5V16.9223H3.36434L10.5095 0.426758H13.6256L20.7707 16.9223H23.6561ZM15.2204 11.2673L12.0833 4.02249L8.94614 11.2673H15.2204Z"
            :fill="colorsShuffled[0]"/>
        <path
            d="M23.6559 16.9223V0.426758H26.5727L36.6136 13.5506V0.426758H39.5304V16.9223H41.9331V18H36.3723L26.5832 5.17485V18H21.2637V16.9223H23.6559Z"
            :fill="colorsShuffled[1]"/>
        <path
            d="M48.166 16.9223V11.0646L41.7344 0.426758H44.924L49.6454 8.41845L54.3669 0.426758H57.5564L51.1248 11.0646V16.9223H57.3571V18H41.9232V16.9223H48.166Z"
            :fill="colorsShuffled[2]"/>
        <path
            d="M59.7596 16.9223V0.426758H62.6764V9.00531L70.5875 0.426758H74.3017L67.3559 8.1197L73.6407 16.9437V16.9223H76.0434V18H70.9547L65.2155 10.307L62.6974 13.0705V18H57.3779V16.9223H59.7596Z"
            :fill="colorsShuffled[3]"/>
        <path
            d="M95.537 16.9223V18H74.7627V16.9223H80.4284C79.0749 16.1328 78.0047 15.0551 77.2283 13.6787C76.4414 12.3023 76.0532 10.7659 76.0532 9.08004C76.0532 6.49794 76.924 4.34262 78.6762 2.60344C80.4284 0.864259 82.5793 0 85.1604 0C87.7309 0 89.8923 0.864259 91.634 2.60344C93.3862 4.34262 94.257 6.49794 94.257 9.08004C94.257 10.7552 93.8688 12.2917 93.0819 13.6787C92.295 15.0658 91.2353 16.1541 89.8818 16.9223H95.537ZM89.4936 4.57737C88.3185 3.33967 86.8706 2.72079 85.1499 2.72079C83.4397 2.72079 81.9917 3.33967 80.8061 4.57737C79.631 5.81507 79.033 7.31949 79.033 9.09068C79.033 10.8619 79.6205 12.3663 80.8061 13.604C81.9812 14.8417 83.4292 15.4606 85.1499 15.4606C86.8601 15.4606 88.308 14.8417 89.4936 13.604C90.6687 12.3663 91.2667 10.8619 91.2667 9.09068C91.2563 7.31949 90.6687 5.81507 89.4936 4.57737Z"
            :fill="colorsShuffled[4]"/>
        <path
            d="M83.2407 10.7128L84.804 9.6458L84.6781 11.5664H84.8459V11.577H85.6119V11.5664H85.7902L85.6643 9.66713L87.2276 10.7341L87.7837 9.75248L86.0945 8.8989L87.7837 8.05598L87.2276 7.08502L85.6748 8.13067L85.7902 6.23145H84.6781L84.7935 8.13067L83.2407 7.07437L82.6846 8.05598L84.3633 8.8989L82.6846 9.75248L83.2407 10.7128Z"
            fill="white"/>
        <path
            d="M70.4506 10.7128L72.0139 9.6458L71.888 11.5664H72.0559V11.577H72.8218V11.5664H73.0002L72.8743 9.66713L74.4376 10.7341L74.9937 9.75248L73.3045 8.8989L74.9937 8.05598L74.4376 7.08502L72.8848 8.13067L73.0002 6.23145H71.888L72.0035 8.13067L70.4506 7.07437L69.8945 8.05598L71.5733 8.8989L69.8945 9.75248L70.4506 10.7128Z"
            fill="white"/>
        <path
            d="M96.0307 10.7128L97.594 9.6458L97.4681 11.5664H97.636V11.577H98.4019V11.5664H98.5803L98.4544 9.66713L100.018 10.7341L100.574 9.75248L98.8845 8.8989L100.574 8.05598L100.018 7.08502L98.4649 8.13067L98.5803 6.23145H97.4681L97.5835 8.13067L96.0307 7.07437L95.4746 8.05598L97.1534 8.8989L95.4746 9.75248L96.0307 10.7128Z"
            fill="white"/>
        <path
            d="M107.792 16.9222C110.699 15.5138 112.147 12.9424 112.147 9.1973C112.147 6.41247 111.318 4.25716 109.649 2.7207C107.981 1.18425 105.599 0.416016 102.504 0.416016H96.5658V5.78294H99.4826V3.15817H102.399C106.932 3.15817 109.198 5.17477 109.198 9.1973C109.198 11.1712 108.642 12.6757 107.53 13.6893C106.418 14.7029 104.813 15.2151 102.725 15.2151H99.4826V12.0248H96.5658V16.9222H94.1631V17.9999H113.5V16.9222H107.792Z"
            :fill="colorsShuffled[5]"/>
    </svg>
</template>
