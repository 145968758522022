<script setup>
import {getCurrencies, getLang, getLangs} from "@/constants.js";
import Button from "@/Components/Button.vue";
import Card from "@/Components/Card.vue";
import Modal from "@/Components/Modal.vue";
import {ref, watch} from "vue";
import {activeCurrency, onSelectCurrency, onSelectLang} from "@/helper.js";

const lang = ref(getLang())
watch(lang, onSelectLang)
const showModalLangs = ref(false);

const currencies = getCurrencies()
const currency = ref(currencies.find(item => item.code === activeCurrency.value.code))
watch(currency, onSelectCurrency)
</script>
<template>
  <button class="text-white lg:hidden flex items-center" @click="showModalLangs = true">
    <ion-icon name="globe"
              class="text-xl  align-middle group-hover:text-blue-400 transition-colors"></ion-icon>
  </button>
  <Modal :show="showModalLangs" @close="showModalLangs = false">
    <Card class="flex flex-col py-5 px-3 gap-6">
      <p class="px-2.5 text-center text-sm font-medium dark:text-slate-200">Выберите язык | Choose language</p>
      <template v-for="itemLang in getLangs()">
        <div class="px-7.5 pb-1.5 flex justify-between">
          <span class="font-normal tracking-wide uppercase dark:text-slate-200">{{ itemLang.name }}</span>
          <input v-model="lang" :checked="lang.code === itemLang.code" :value="itemLang" type="radio">
        </div>
      </template>
      <p class="px-2.5 text-center text-sm font-medium dark:text-slate-200">Выберите валюту | Choose currency</p>
        <template v-for="itemCurrency in currencies">
        <div class="px-7.5 pb-1.5 flex justify-between">
          <span class="font-normal tracking-wide uppercase dark:text-slate-200">{{ itemCurrency.code }}</span>
            <input v-model="currency" :checked="currency.code === itemCurrency.code" :value="itemCurrency" type="radio">
        </div>
      </template>
      <div class="flex mx-auto">
        <Button class="uppercase" text="ok" type="primary" @click="showModalLangs = false"/>
      </div>

    </Card>
  </Modal>
</template>

<style scoped>
[type='radio']:checked {
  border-color: transparent;
  background-color: currentColor;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}
</style>
