<script setup>
import {clickOutSide as vClickOutside} from "@/vClickOutside.js";
import {computed, ref, watch} from "vue";
import SearchResult from "@/Components/SearchResult.vue";
import {router, usePage} from "@inertiajs/vue3";
import debounce from "lodash-es/debounce.js";
import Spinner from "@/Components/Icons/Spinner.vue";
import SearchIcon from "@/Components/Icons/Search.vue";
import {route} from "ziggy-js";

const page = usePage();
const open = ref(false)
const search = ref(''),
    isLoading = ref(false)

const emit = defineEmits(['onFocus', 'onClose'])
const hide = () => {
    open.value = false
    emit('onClose')
}

const products = ref([])
const showClear = computed(() => search.value && search.value.length > 0 && !isLoading.value)
const updateSearchValue = (value) => {
    search.value = value
}

const getQueryParams = () => {
    return {
        products_search: search.value ? search.value : undefined
    }
}

const fetch = (nextPage = false) => {
    open.value = true
    isLoading.value = true
    const queryParams = getQueryParams(nextPage)
    router.get(page.url, queryParams,
        {
            preserveState: true,
            preserveScroll: true,
            only: ['products_search'],
            onFinish: () => {
                products.value = page.props.products_search
                isLoading.value = false
            }
        })
}
const fetchDebounced = debounce(fetch, 1000)
watch(search, () => {
        if (search.value.length > 0) {
            fetchDebounced(false)
        }
    }
)

const clearValue = () => {
    open.value = false
    search.value = ''
}


</script>

<template>
        <div class="relative py-2" v-click-outside="hide">
            <div class="flex">
                <label for="default-search"
                       class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
                <div class="relative w-full">
                    <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <SearchIcon/>
                    </div>
                    <input type="text"
                           class="block w-full pl-9 placeholder:font-medium font-medium p-1 dark:placeholder-slate-400 lg:py-2 dark:bg-dark-blue text-base lg:text-sm text-gray-900 dark:text-slate-500 border border-slate-400 rounded-full bg-gray-50 focus:ring-blue-600 focus:border-blue-600"
                           :placeholder="$t('Search games, subscriptions, etc.')" required
                           v-model="search"
                           @focus="emit('onFocus')"
                           @keyup.enter="router.get(route('products.index', {search: search ? search : undefined}))"
                    >
                    <!--                    <button type="submit"-->
                    <!--                            class="text-white absolute right-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">-->
                    <!--                        Искать-->
                    <!--                    </button>-->
                    <div v-if="isLoading" class="absolute inset-y-0 right-0 flex">
                        <Spinner class="self-center"/>
                    </div>
                    <div v-if="showClear"
                         class="absolute inset-y-0 right-0 flex items-center pr-3 z-10 text-slate-500 cursor-pointer"
                         @click="clearValue"
                    >
                        <ion-icon name="close-circle" class="text-xl"></ion-icon>
                    </div>
                </div>
            </div>
            <SearchResult v-if="!isLoading && open" :search-result="products"/>
        </div>
</template>
